import React from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';
import { Typography } from '@one-thd/sui-atomic-components';

import { propTypesEmtQuestionsAndAnswers } from './constants';
import { emtContentDataModel } from '../dataModels';

const EmtQuestionsAndAnswersComponent = ({ nValue }) => {
  const finalNValue = nValue?.indexOf('N-') < 0 ? `N-${nValue}` : nValue;

  const { data, loading, error } = useDataModel('emtContent', {
    variables: {
      contentId: finalNValue,
      pageName: 'plp'
    },
    skip: !nValue
  });

  const questionsAndAnswers = data?.emtContent?.content?.questionAndAnswer?.Questions || [];

  if (error || loading || !data || !questionsAndAnswers || questionsAndAnswers.length === 0) return null;

  return (
    <div className="sui-flex sui-flex-col sui-gap-4" data-component="QuestionsAndAnswers">
      <Typography variant="h2" weight="display">Questions & Answers</Typography>
      <div className="sui-flex sui-flex-col sui-gap-3">
        {questionsAndAnswers.map((qa, index) => (
          <div key={index}>
            <Typography variant="body-base" weight="bold" dangerouslySetInnerHTML={{ __html: qa.Question }} />
            <Typography variant="body-base" dangerouslySetInnerHTML={{ __html: qa.Answer }} />
          </div>
        ))}
      </div>
    </div>
  );
};

EmtQuestionsAndAnswersComponent.propTypes = propTypesEmtQuestionsAndAnswers;

EmtQuestionsAndAnswersComponent.displayName = 'EmtQuestionsAndAnswers';

EmtQuestionsAndAnswersComponent.dataModel = emtContentDataModel;

export { EmtQuestionsAndAnswersComponent };
